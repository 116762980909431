<template>
  <div class="student">
    <div class="mt-5 px-4">
      <div class="d-flex mb-5 align-items-center student__top--bg pe-4">
        <img class="me-3 rounded-circle student__roundImg"
          src="https://img2.metalearning.com.tw/i/fs/Avatar/20220524/16534044411fd7ab39479648f1852295a4dd37e487.jpg"
          alt="Profile"
          ref="roundImg">
        <h3 class="fw-bold student__title">學生帳號測試</h3>
        <div class="d-flex align-items-center ms-auto student__state">
          <vue-ellipse-progress
            :progress="progress"
            :size="80"
            :legend="true"
            :thickness="6"
            :color="'#4158d0'"
            :emptyThickness="6"
            :emptyColorFill="'#dde6f0'"
            :emptyColor="'#fff'"
            class="me-3 ms-auto">
          </vue-ellipse-progress>
          <div class="text-start student__state__txt">
            <h4 class="mb-3">已完成 <strong class="fw-bold">{{ endChapterId.length }}</strong> 單元</h4>
            <h4>已完成 <strong class="fw-bold">{{ progress }}</strong>% 課程</h4>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div class="course__accor py-3 py-md-5 text-start">
          <h2 class="text-muted fw-bold mb-3" style="font-size: 1.2rem;">單元完成狀態</h2>
          <div class="accordion" id="accordionExample">
            <div class="accordion-item" v-for="(item, i) in chapters" :key="i">
              <h2 class="accordion-header" :id="`L${i}`">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse${i}`" aria-expanded="true" :aria-controls="`collapse${i}`">
                  {{ item.title }}
                </button>
              </h2>
                <div :id="`collapse${i}`" class="accordion-collapse collapse show" :aria-labelledby="`heading${i}`" >
                <div class="accordion-body p-2">
                  <ul>
                    <li class="course__accor__list d-flex justify-content-between"
                      v-for="(sub, j) in item.chaperItem" :key="j"
                      >
                      <template v-if="!sub.completed">
                        <i class="far fa-circle me-2 d-inline-block"></i>
                      </template>
                      <template v-if="sub.completed">
                        <i class="fas fa-check-circle me-2 d-inline-block" ></i>
                      </template>
                      <span class="title" style="flex: 1 1 0%;">{{ sub.title }}</span>
                      <span class="time">{{ formatTime(sub.duration.min) }} : {{ formatTime(sub.duration.sec) }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
import { VueEllipseProgress } from 'vue-ellipse-progress';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Teacher__Student',
  components: {
    VueEllipseProgress,
  },
  data() {
    return {
      progress: 10,
      chapters: [],
      id: 0,
      uid: 0,
      totalChapter: 0,
      totalSection: 0,
      chapterIdData: [],
      course: [],
      arr: [],
      endChapterId: [],
      metaTitle: '學生管理 / 課程 / 管理 - 元學堂',
    };
  },
  metaInfo: {
    title: "學生管理 / 課程 / 管理 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '學生管理 / 課程 / 管理 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  methods: {
    getCourse() {
      this.$http.get(`${this.$API_PATH}/Course/Chapter?Id=${this.cid}`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.chapterIdData = res.data.data;
        return this.$http.get(`${this.$API_PATH}/Course?CourseId=${this.cid}`, {
          headers: {
            Accept: '*/*',
            Authorization: `bearer ${this.userAuth}`,
          },
        });
      }).then((res) => {
        if (res.data.data.chapter.length > 0) {
          this.course = res.data.data;
          const arr = Array.from(res.data.data.chapter);

          this.chapterIdData.forEach((item, i) => {
            item.subChapter.forEach((el, idx) => {
              arr[i].chaperItem[idx] = { ...arr[i].chaperItem[idx], chapterId: el.id, completed: false };
            });
          });

          let chapter = 0;
          let section = 0;
          arr.forEach((item) => {
            chapter++;
            item.chaperItem.forEach((el) => {
              section++;
            });
          });
          this.totalChapter = chapter;
          this.totalSection = section;
          this.arr = arr;

          return this.$http.get(`${this.$API_PATH}/Admin/Course/StudentManagement/${this.cid}/${this.uid}`,
            {
              headers: {
                Accept: '*/*',
                Authorization: `bearer ${this.userAuth}`,
              },
            });
        }
        Swal.fire(
          '',
          '該門課程內容正在編輯中，請稍後再試',
          'info',
        ).then((result) => {
          if (result.isConfirmed) {
            this.$router.replace('/');
          }
        });
      }).then((res) => {
        res.data.data.courseProgresss.forEach((item, idx) => {
          if (item.completed) {
            this.endChapterId.push(item.chapterId);
          }
          this.arr.forEach((el, i) => {
            el.chaperItem.forEach((element, n) => {
              if (element.chapterId === item.chapterId) {
                element.completed = item.completed;
              }
            });
          });
        });
        this.chapters = [...this.arr];
        this.progress = Number((this.endChapterId.length / this.totalSection * 100).toFixed(0));
        // console.log(this.chapters);
      })
        .catch((err) => {
          // console.log(err);
        });
    },
    formatTime(num) {
      return num < 10 ? `0${num}` : num;
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userAuth', 'LoginProvider', 'role']),
  },
  created() {
    this.cid = this.$route.params.cid;
    this.uid = this.$route.params.id;
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    // console.log(this.role);
    if (this.userAuth.length == 0) {
      this.$router.push('/login');
    } else if (this.role == 'Member') {
      this.$router.push('/');
    }
    this.getCourse();
  },
};
</script>

<style lang="scss" scoped>
  .student .course__accor__list .title::after {
    position: absolute;
    content: '';
    font-weight: 700;
    color: #012970;
    right: 1rem;
    top: 0;
    opacity: 0;
    transition: all .4s;
}

.student .course__accor__list:hover .title::after {
    opacity: 0;
}

.student {
  &__roundImg {
    width: 100px;
    height: 100px;
    @media screen and (max-width: 768px) {
      width: 80px;
      height: 80px;
    }
  }
  &__title {
    font-size: 1.2rem;
  }
  &__state {
    &__txt {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}
</style>
